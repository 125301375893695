import app, { db } from "../config/firebase";
import moment from "moment";
import axios from "axios";

axios.defaults.baseURL =
  "https://europe-west1-projet-homy.cloudfunctions.net/api";

// Codes
export const codesLoc = [
  // "z033Kz9",
  // "W9982t8",
  // "c0281uZ",
  "BOUVGR1",
  "BOUVGR2",
  "BOUVGR3",
  "BOUVGR4",
  "BOUVGR5",
  "BOUVGR6",
  "BOUVGR7",
  "BOUVGR8",
  // "3lvI781",
  // "y3593jD",
  // "29r63Ws",
  // "mB80i66",
  // "2AV3i51",
  // "Ceu7677",
  // "4p989S4",
  // "2w23Cc3",
  // "F90Q2y9",
  // "3p1G87f",
  // "Y2396tU",
  // "570QTc6",
  // "k195Z1S",
  // "L6W190h",
  // "n814Hh5",
  // "63mF8I1",
  // "x6X979r",
  // "08v0B6R",
  // "8O3y87e",
  // "23K8Hj8",
  // "aS9480K",
  // "92f9Nq8",
  // "4i43oO7",
  // "Dg84C34",
  // "uy6W664",
  // "eU872l1",
  // "3a81Z2P",
  // "759cB6n",
  // "969Kxi1",
  // "t90W15R",
  // "v2854Cx",
  // "408El9P",
  // "sI4077B",
  // "77R67gx",
  // "9ME32w4",
  // "14YRs94",
  // "45g14SD",
  // "9I1j36V",
  // "5RP1d33",
  // "u2p093H",
  // "5z1Up89",
  // "YF3i824",
  // "4p345VV",
  // "K0647Ur",
  // "2w32E3W",
  // "zJ42a96",
  // "28Cz8o8",
  // "CpD0348",
  // "5YT6b25",
  // "1O2B19m",
  // "6I16Ah4",
  // "s709AH7",
  // "46H2m3d",
  // "483e0Wu",
  // "bE98w73",
  // "69g2q9U",
  // "07F8a8L",
  // "6W35Ti4",
  // "79v4vM1",
  // "08Xr33k",
  // "4329pcX",
  // "G69x50K",
  // "757Jn7I",
  // "2m505MI",
  // "U291Px4",
  // "8N91I5q",
  // "34dCS09",
  // "26z4lK3",
  // "1r39dD1",
  // "598Y1mr",
  // "P5Kg093",
  // "O8X8n82",
  // "B8Bh011",
  // "45tL02z",
  // "w7X2V57",
  // "H403v3j",
  // "56IJ23g",
  // "3dv865F",
  // "c58e88L",
  // "873bl4S",
  // "2bz7G43",
  // "5A1e7f5",
  // "X460r5O",
  // "04T8E0w",
  // "636O2Cp",
  // "n4807Yy",
  // "B14F77s",
  // "n8Tf551",
  // "D3Ut485",
  // "E9Ko457",
  // "8E78k4o",
  // "76hV5q5",
  // "o4a3J97",
  // "0r005dB",
  // "1wWU846",
  // "458Gy6U",
  // "9XM408b",
  // "43Bfa84",
  // "j0E02l1",
  // "V29e13x",
  // "H34136s",
  // "N46e82n",
  // "do02H97",
  // "6xOJ988",
  // "5bzZ744",
  // "8BM72g2",
  // "U1o91G4",
  // "j40H3K9",
  // "246pvI5",
  // "83TQ88b",
  // "dR235B5",
  // "X8j6U14",
  // "x58P18C",
  // "77S5jJ4",
  // "2rA1s72",
  // "z055G4W",
  // "N290o7e",
  // "74Ca97i",
  // "j4D78k8",
  // "ZW3675d",
  // "d35a6D3",
  // "962Moh3",
  // "826A0hO",
  // "U70d5m2",
  // "3r94OC4",
  // "0515hFh",
  // "8ho8W34",
  // "5342T7h",
  // "e248oW5",
  // "TK4444d",
  // "DUp2661",
  // "7K04mi7",
  // "v2Cm728",
  // "0y72q7G",
  // "8gA375B",
  // "6d5W66J",
  // "Gt4470H",
  // "4174z6R",
  // "69Z1vO2",
  // "20Z77Zj",
  // "3Fo15N3",
  // "ju853P1",
  // "4Nhg686",
  // "2AX32f7",
  // "5Ir7K28",
  // "x76Tb81",
  // "79R756o",
  // "263eQ1k",
  // "M57k0i2",
  // "N759p0E",
  // "212s9Ax",
  // "8B84r6s",
  // "6Sx813Y",
  // "890l7Tm",
  // "L761e1s",
  // "yJA5262",
  // "56hN6Z0",
  // "09N0R2f",
  // "3C25Lp4",
  // "54KPk86",
  // "1L28q3u",
  // "765ek7D",
  // "4zgH659",
  // "3Z99el0",
  // "s3789gT",
  // "9Bp8172",
  // "2066hEd",
  // "J0154Cv",
  // "0ej114B",
  // "4exN114",
  // "8949FcX",
  // "9L0yJ79",
  // "f368QQ0",
  // "vt815J6",
  // "607qk4V",
  // "Ci2a066",
  // "d6S8D31",
  // "9V86x6B",
  // "7WF59t4",
  // "81z6hF2",
  // "f0435xZ",
  // "58Z109s",
  // "5Pg45h3",
  // "WH3y729",
  // "4J6O51i",
  // "0w04HA4",
  // "8e9A3i5",
  // "7iA948Z",
  // "7d7y74X",
  // "59i0b7X",
  // "5at3T29",
  // "s30g93G",
  // "9MxK041",
  // "q4FN129",
  // "76x3C1g",
  // "248bV2r",
  // "3pF937L",
  // "82m33RM",
  // "1573VdB",
  // "3557rY5",
  // "Y610z09",
  // "3v7Ct38",
];

export const checkValidity = (value, rules) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.regex) {
    isValid = rules.regex.test(value) && isValid;
  }

  if (rules.codes) {
    isValid = rules.codes.includes(value) && isValid;
  }

  return isValid;
};

// custom token login
export const customLogin = (refLoc, dispatch) => {
  // console.log("Axios function");
  axios
    .post("/login-bouvet", { code: refLoc.value })
    .then((res) => {
      // console.log(res);
      app
        .auth()
        .signInWithCustomToken(res.data.token)
        .then((res) => {
          // console.log("Auth success: ", res.data);
          dispatch({ type: "SET_LOADING", loading: false });
        })
        .catch((err) => {
          console.log("auth error: ", err);
          dispatch({ type: "SET_LOADING", loading: false });
        });
    })
    .catch((err) => {
      console.log("axios error: ", err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Anon Login
export const anonLogin = (dispatch) => {
  app
    .auth()
    .signInAnonymously()
    .then((resp) => {
      console.log("Login successful");
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Add new locataire
export const addNewLoc = (refLoc, locataireID) => {
  const newLoc = {
    refLoc,
    locataireID,
    dateCreation: moment().format(),
  };
  if (refLoc !== "") {
    db.collection("locataires")
      .doc(refLoc)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  } else {
    db.collection("locataires")
      .doc(`anon-${locataireID}`)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  }
};
